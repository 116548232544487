import _ from 'lodash';
import { TRANSCODING_STATUSES } from '@wix/wix-vod-constants/dist/common/transcoding-statuses';
import { CHANNEL_TYPES } from '@wix/wix-vod-constants/dist/common/channel-types';
import { keysToCamelCase } from '@wix/wix-vod-shared/dist/src/common/utils/object';
import { normalizeId } from 'api/normalizers/entity-id';
import { parseVideoItem } from '@wix/wix-vod-api/dist/src/parsers/video-item';
export var parseCatalogChannel = function (channel) {
    channel = keysToCamelCase(channel);
    var id = channel.itemId, customCoverUrl = channel.customCoverUrl, videosCount = channel.usedCount, hasChangesToPublish = channel.publishChanges, _a = channel.urlType, urlType = _a === void 0 ? '' : _a, _b = channel.channelType, channelType = _b === void 0 ? CHANNEL_TYPES.CHANNEL : _b;
    var createdTs = channel.createdTs, externalCreatedTs = channel.externalCreatedTs, updatedTs = channel.updatedTs, _c = channel.publishedTs, publishedTs = _c === void 0 ? createdTs : _c, statsInfo = channel.statsInfo;
    createdTs = new Date(createdTs * 1000);
    externalCreatedTs = new Date(externalCreatedTs * 1000);
    updatedTs = new Date(updatedTs * 1000);
    if (publishedTs) {
        publishedTs = new Date(publishedTs * 1000);
    }
    // WHY ?!?!
    if (statsInfo) {
        statsInfo = _.mapValues(statsInfo, function (list) {
            // get rid of empty categories & tags
            var nonEmptyList = _.filter(list, function (item) { return Boolean(item.value); });
            return _.uniqBy(nonEmptyList, 'value');
        });
    }
    // TODO: USE SERVER SIDE MAPPING
    channel = _({})
        .assign(channel, {
        // TODO: either move to wix-vod or normalize on server side
        id: normalizeId(id),
        customCoverUrl: customCoverUrl || null,
        videosCount: videosCount,
        createdTs: createdTs,
        externalCreatedTs: externalCreatedTs,
        updatedTs: updatedTs,
        publishedTs: publishedTs,
        hasChangesToPublish: hasChangesToPublish,
        urlType: urlType,
        channelType: channelType,
        statsInfo: statsInfo,
    })
        .omit('itemId', 'usedCount', 'publishChanges')
        .value();
    channel = parseFeaturedItem(channel);
    channel = cleanupVideoCountsForYoutubeFeed(channel);
    return channel;
};
function parseFeaturedItem(channel) {
    if (channel.featuredItem) {
        // TODO: remove after add filtering on server side. Fix failed video, marked as featured
        if (!channel.featuredItem.mediaStatus ||
            channel.featuredItem.mediaStatus === TRANSCODING_STATUSES.NONE) {
            channel = _.omit(channel, 'featuredItem');
            if (channel.videosCount) {
                channel.videosCount += -1;
            }
        }
        else if (channel.featuredItem.itemId) {
            // cleanup in new VOD backend version
            channel.featuredItem = parseVideoItem(channel.featuredItem);
        }
    }
    return channel;
}
function cleanupVideoCountsForYoutubeFeed(channel) {
    // skip videosCount for youtube feed, should be done on BE
    if (channel.channelType !== CHANNEL_TYPES.CHANNEL) {
        channel = _.omit(channel, 'videosCount');
    }
    return channel;
}

import { __assign, __awaiter, __generator } from "tslib";
import { isVODChannel } from 'shared/utils/channel-helpers';
import createAsyncActions from 'shared/redux/helpers/create-async-actions';
import { normalizeVideo } from 'shared/redux/helpers/normalized';
import { getChannelById } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import { getYoutubeVideoById as _getYoutubeVideoById } from '@wix/wix-vod-api/dist/src/public/youtube/videos';
import { getChannelVideoById as _getChannelVideoById } from '@wix/wix-vod-api/dist/src/public/channel-videos/channel-videos';
import { getHydratedData } from 'widget/redux/client/hydrated-data/hydrated-data';
import { createAsyncProxy } from 'shared/worker/lib';
var ACTIONS = createAsyncActions('SERVER.CHANNEL.VIDEO.GET');
export var NAMES = ACTIONS.NAMES;
var START = ACTIONS.START, SUCCESS = ACTIONS.SUCCESS, FAIL = ACTIONS.FAIL;
export var getPublicVideo = createAsyncProxy('video.getPublicVideo', function (channelId, videoId) { return function (dispatch, getState, _a) {
    var withContext = _a.withContext;
    return __awaiter(void 0, void 0, void 0, function () {
        var state, params, channel, _b, singleVideoId, singleVideo, templateMetaSiteId, response, getChannelVideoById, getYoutubeVideoById, _c, error_1, error_2;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    state = getState();
                    params = { channelId: channelId, videoId: videoId };
                    channel = getChannelById(state, channelId);
                    _b = getHydratedData(state), singleVideoId = _b.singleVideoId, singleVideo = _b.singleVideo, templateMetaSiteId = _b.templateMetaSiteId;
                    dispatch(START(params));
                    getChannelVideoById = withContext(_getChannelVideoById);
                    getYoutubeVideoById = withContext(_getYoutubeVideoById);
                    _d.label = 1;
                case 1:
                    _d.trys.push([1, 14, , 15]);
                    if (!(singleVideoId === videoId)) return [3 /*break*/, 3];
                    return [4 /*yield*/, getChannelVideoById(channelId, videoId, {
                            hydratedVideo: singleVideo,
                            templateMetaSiteId: templateMetaSiteId,
                        })];
                case 2:
                    response = _d.sent();
                    return [3 /*break*/, 13];
                case 3:
                    if (!channel) return [3 /*break*/, 8];
                    if (!isVODChannel(channel)) return [3 /*break*/, 5];
                    return [4 /*yield*/, getChannelVideoById(channelId, videoId, {
                            templateMetaSiteId: templateMetaSiteId,
                        })];
                case 4:
                    _c = _d.sent();
                    return [3 /*break*/, 7];
                case 5: return [4 /*yield*/, getYoutubeVideoById(videoId)];
                case 6:
                    _c = _d.sent();
                    _d.label = 7;
                case 7:
                    response = _c;
                    return [3 /*break*/, 13];
                case 8:
                    _d.trys.push([8, 10, , 13]);
                    return [4 /*yield*/, getChannelVideoById(channelId, videoId, {
                            templateMetaSiteId: templateMetaSiteId,
                        })];
                case 9:
                    response = _d.sent();
                    return [3 /*break*/, 13];
                case 10:
                    error_1 = _d.sent();
                    if (!(error_1.status === 404)) return [3 /*break*/, 12];
                    return [4 /*yield*/, getYoutubeVideoById(videoId)];
                case 11:
                    response = _d.sent();
                    _d.label = 12;
                case 12: return [3 /*break*/, 13];
                case 13:
                    response = __assign(__assign({}, response), { data: normalizeVideo(response.data) });
                    dispatch(SUCCESS(params, response));
                    return [2 /*return*/, response];
                case 14:
                    error_2 = _d.sent();
                    dispatch(FAIL(params, error_2, {
                        analytics: { type: 'error', name: 'shared.channel.video.get' },
                    }));
                    return [3 /*break*/, 15];
                case 15: return [2 /*return*/];
            }
        });
    });
}; });

import { getCurrenciesInfo } from '@wix/wix-vod-constants/dist/common/currencies';
var currencies = getCurrenciesInfo();
export default function getDefaultChannelDealInfo(currency) {
    return [
        {
            type: 'subscription',
            price: currencies[currency].subscription.default,
            enabled: false,
        },
    ];
}

import { __assign } from "tslib";
import { VIEW_MODES } from '@wix/wix-vod-constants/dist/common/view-modes';
import { getAppLocale } from 'services/locale/locale';
var asAxiosResponse = function (data) { return (data ? { data: data } : null); };
var parseServer = function (source) {
    var locale = source.__LOCALE__;
    var translations = source.__TRANSLATIONS__;
    var singleVideo = source.__SINGLE_VIDEO__;
    var channelVideos = source.__VIDEOS__;
    var memberInfo = source.__MEMBER_INFO__;
    var viewMode = source.viewMode;
    var currentSiteUser = source.__CURRENT_SITE_USER__;
    var metaSiteId = source.metaSiteId;
    var templateMetaSiteId = source.templateMetaSiteId;
    return {
        biToken: source.biToken,
        experiments: source.__EXPERIMENTS__,
        migrationInfo: source.__MIGRATION_INFO__,
        windowSize: source.windowSize,
        metaSiteId: metaSiteId,
        locale: locale,
        translations: translations,
        siteUrl: source.__SITE_URL__,
        staticsVersion: source.staticsVersion || __CI_APP_VERSION__,
        editorType: source.__EDITOR_TYPE__,
        channel: asAxiosResponse(source.__CHANNEL__),
        channelVideos: asAxiosResponse(channelVideos),
        memberInfo: asAxiosResponse(memberInfo),
        singleVideo: asAxiosResponse(singleVideo),
        singleVideoId: singleVideo && singleVideo.base_item_id,
        instance: source.instance,
        instanceId: source.instanceId,
        viewMode: viewMode,
        compId: source.compId,
        currentSiteUser: viewMode === VIEW_MODES.SITE ? currentSiteUser : null,
        deviceType: source.deviceType,
        templateMetaSiteId: templateMetaSiteId,
    };
};
var parseClient = function (source) {
    var serverResult = parseServer(source);
    var translations = serverResult.translations;
    var currentPageId = source.currentPageId, siteLocale = source.siteLocale, siteOwnerId = source.siteOwnerId, fullSiteUrl = source.fullSiteUrl, appSettings = source.appSettings, templateMetaSiteId = source.templateMetaSiteId;
    var locale = serverResult.locale || getAppLocale({ appSettings: appSettings, siteLocale: siteLocale });
    return __assign(__assign({}, serverResult), { currentPageId: currentPageId,
        locale: locale,
        translations: translations,
        siteOwnerId: siteOwnerId,
        fullSiteUrl: fullSiteUrl,
        templateMetaSiteId: templateMetaSiteId });
};
export var parseHydratedData = function (source) {
    return parseClient(source);
};

import { schema } from 'normalizr';
var channel = new schema.Entity('channels');
var paymentInfoChannel = new schema.Entity('channels', {}, {
    idAttribute: 'listId',
});
var arrayOfChannels = [channel];
var video = new schema.Entity('videos');
var paymentInfoVideo = new schema.Entity('videos', {}, { idAttribute: 'itemId' });
var arrayOfVideos = [video];
var arrayOfPaymentInfoVideos = [paymentInfoVideo];
channel.define({
    featuredItem: video,
});
video.define({
    usedInLists: arrayOfChannels,
});
export { channel, paymentInfoChannel, arrayOfChannels, video, paymentInfoVideo, arrayOfVideos, arrayOfPaymentInfoVideos, };

import * as channelVideosModule from '@wix/wix-vod-api/dist/src/public/channel-videos/channel-videos';
import * as channelModule from 'api/public/channel/channel';
import * as videoCardsModule from 'api/public/video-cards/video-cards';
import * as videosModule from '@wix/wix-vod-api/dist/src/public/video/video';
import { fetchAdapter } from '@wix/wix-vod-api-site-based/dist/src/utils/axios-fetch-adapter';
import { setSiteBasedUrls } from 'services/site-based-urls';
import { setInstanceGetter } from '@wix/wix-vod-shared/dist/src/common/utils/get-auth-header';
// import { withContexts } from '@wix/wix-vod-shared/dist/src/common/utils/context';
var configureService = function (_a) {
    var service = _a.service, instance = _a.instance;
    var axiosInstance = service.getAxiosInstance
        ? service.getAxiosInstance()
        : service;
    axiosInstance.defaults.adapter = fetchAdapter;
    // if (__OOI__) {
    //   axiosInstance.defaults.headers.common = {
    //     ...axiosInstance.defaults.headers.common,
    //     'X-WIX-INSTANCE-TOKEN': instance,
    //   };
    //   // https://github.com/axios/axios/issues/1522
    // }
    return service;
};
export var configureAxios = function (_a) {
    var instance = _a.instance;
    setSiteBasedUrls();
    var modules = [
        channelVideosModule,
        channelModule,
        videoCardsModule,
        videosModule,
    ];
    var services = modules.map(function (module) {
        var service = module.service;
        // const service = module.createService();
        configureService({ service: service, instance: instance });
        // module.ctx.provide(undefined); //cleaning default context to notice an error
        // return { val: service, ctx: module.ctx };
    });
    setInstanceGetter(function () { return instance; });
};

export var normalizeId = function (id) {
    if (!id) {
        return id;
    }
    /*
    We are getting channel and video ids from backend in form 75597969-d0e0fda03fb64c7bbed8746046bff110,
    where 75597969 - publish id, d0e0fda03fb64c7bbed8746046bff110 - item id.
    In case of channel for single videos channel id has form 75597969-97921f1d-2044-4b4b-9973-620cc8d0deb8,
    where 75597969 - publish id, 97921f1d-2044-4b4b-9973-620cc8d0deb8 - channelId, same as Wix User id.
    Normalization here assumes stripping publish id
    */
    return id.substring(id.indexOf('-') + 1);
};

import { __assign } from "tslib";
import { PublicVideoCards } from '@wix/wix-vod-api-site-based/dist/src/public/services/video-cards/video-cards';
import { parseCards } from '@wix/wix-vod-shared/dist/src/common/parsers/cards/cards';
import { toError } from 'api/parsers/transport-error';
import { publicCatalogServiceConfig } from 'api/config/catalog-service';
import { createCtx } from '@wix/wix-vod-shared/dist/src/common/utils/context';
export var createService = function () {
    return new PublicVideoCards(publicCatalogServiceConfig);
};
export var service = createService();
export var ctx = createCtx();
export var getVideoCards = function (channelId, videoId) {
    // const service = ctx.getValue();
    return service
        .getList(channelId, videoId)
        .then(function (response) { return (__assign(__assign({}, response), { data: parseCards(response.data) })); })
        .catch(toError);
};

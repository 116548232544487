import _ from 'lodash';
import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import { video as videoSchema } from 'shared/schemas';
import { isFree as isVideoFree, isSaleEnabled, } from '@wix/wix-vod-shared/dist/src/common/ui-selectors/video/pricing';
import { PREVIEW_TYPES } from '@wix/wix-vod-constants/dist/common/preview-types';
import { getDealInfo, getAllowDownload, getMemberOnly } from './vod-settings';
function withDealInfoFromSettings(state, video) {
    if (!video) {
        return video;
    }
    // TODO: move to entities reducer
    video.dealInfo = _.isEmpty(video.dealInfo)
        ? getDealInfo(state)
        : video.dealInfo;
    var memberOnly = _.isUndefined(video.memberOnly)
        ? getMemberOnly(state)
        : video.memberOnly;
    video.memberOnly = isVideoFree(video) ? memberOnly : false;
    var allowDownload = _.isUndefined(video.allowDownload)
        ? getAllowDownload(state)
        : video.allowDownload;
    video.allowDownload =
        isVideoFree(video) || isSaleEnabled(video) ? allowDownload : false;
    if (_.isEmpty(video.previewType)) {
        video.previewType = isVideoFree(video)
            ? PREVIEW_TYPES.NONE
            : PREVIEW_TYPES.FIRST_20_SEC;
    }
    return video;
}
export var getEntities = function (state) { return state.entities; };
export var getVideoEntities = createSelector(getEntities, _.property('videos'));
var getVideosCount = createSelector(getVideoEntities, _.size);
export function isFetching(state) {
    return state.videos.activeRequests !== 0;
}
export function getVideoIds(state) {
    return state.videos && state.videos.ids;
}
export function getVideoById(state, videoId) {
    return getVideoEntities(state)[videoId];
}
export var hasMoreThanOneVideo = createSelector(getVideosCount, function (count) { return count > 1; });
/**
 * @param state
 * @param videoId {String}
 * @returns {Object} video
 */
export var getVideoWithChannelDataById = createSelector([function (state) { return state; }, getVideoById], function (state, videoData) {
    // TODO remove denormalize. Used to add channel data to usedInLists
    return withDealInfoFromSettings(state, denormalize(videoData, state.entities, videoSchema));
});
/**
 * @param state
 * @returns {Object} {id: video}
 */
export var getVideosGroupedByIds = createSelector([function (state) { return state; }, getVideoEntities], function (state, videosByIds) {
    return _.mapValues(videosByIds, _.partial(withDealInfoFromSettings, state));
});
/**
 * @param state
 * @param ids {String[]}
 * @returns {Object[]} videos list
 */
export var getVideosByIds = createSelector([getVideosGroupedByIds, function (state, ids) { return ids; }], _.pick);
/**
 * @param state
 * @param ids {String[]}
 * @param currentVideoId {String}
 * @returns {Object[]} videos list
 */
export var getVideosByIdsWithoutCurrent = createSelector([getVideosByIds, function (state, ids, currentVideoId) { return currentVideoId; }], _.omit);

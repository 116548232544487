import { __assign } from "tslib";
import _ from 'lodash';
import createAsyncActions from 'shared/redux/helpers/create-async-actions';
import { normalizeChannel } from 'shared/redux/helpers/normalized';
import { getPublic as getChannelPublic } from 'api/public/channel/channel';
import { getHydratedData } from 'widget/redux/client/hydrated-data/hydrated-data';
import { createAsyncProxy } from 'shared/worker/lib';
import getDefaultChannelDealInfo from 'shared/constants/channel-deal-info';
var ACTIONS = createAsyncActions('SERVER.CHANNEL.GET');
export var NAMES = ACTIONS.NAMES;
var START = ACTIONS.START, SUCCESS = ACTIONS.SUCCESS, FAIL = ACTIONS.FAIL;
export var getPublic = createAsyncProxy('channel.getPublic', function (id) { return function (dispatch, getState, _a) {
    var withContext = _a.withContext;
    var params = { id: id };
    var _b = getHydratedData(getState()), channel = _b.channel, templateMetaSiteId = _b.templateMetaSiteId;
    dispatch(START(params));
    return withContext(getChannelPublic)(id, {
        hydratedChannel: channel,
        templateMetaSiteId: templateMetaSiteId,
    }).then(function (response) {
        var data = response.data;
        var vodSettings = getState().vodSettings;
        if (!_.get(data, 'dealInfo.length') && vodSettings) {
            data.dealInfo = getDefaultChannelDealInfo(vodSettings.currency);
        }
        response = __assign(__assign({}, response), { data: normalizeChannel(data) });
        dispatch(SUCCESS(params, response));
        return response;
    }, function (response) {
        dispatch(FAIL(params, response, {
            analytics: { type: 'error', name: 'shared.channel.video.get' },
        }));
    });
}; });

var _a;
import { __assign } from "tslib";
import _ from 'lodash';
import { createAction, handleActions } from 'redux-actions';
import { parseHydratedData } from './parsers';
/* actions */
export var SET_HYDRATED_DATA = 'CLIENT.HYDRATED_DATA.SET';
export var CLEAR_HYDRATED_DATA = 'CLIENT.HYDRATED_DATA.CLEAR';
export var setHydratedData = createAction(SET_HYDRATED_DATA);
export var clearHydratedData = createAction(CLEAR_HYDRATED_DATA);
export var setHydratedDataFromSource = function (source) { return function (dispatch) {
    var parsedData = parseHydratedData(source);
    dispatch(setHydratedData(parsedData));
}; };
var defaultState = {
    isFetching: false,
    data: {},
};
var merge = function (state, _a) {
    var payload = _a.payload;
    return (__assign(__assign({}, state), { data: _.merge({}, state.data, payload) }));
};
var clear = function (state) { return (__assign(__assign({}, state), { data: _.omit(state.data, ['channelVideos', 'memberInfo', 'singleVideo']) })); };
/* reducer */
export var reducer = handleActions((_a = {},
    _a[SET_HYDRATED_DATA] = merge,
    _a[CLEAR_HYDRATED_DATA] = clear,
    _a), defaultState);
/* selectors */
export var getHydratedData = function (state) { return state.hydratedData.data; };
var getKey = function (key) { return function (state) { return _.get(state.hydratedData.data, key); }; };
export var getCurrentPageId = getKey('currentPageId');
export var getInstance = getKey('instance');
export var getSiteOwnerId = getKey('siteOwnerId');
export var getUid = getKey('currentSiteUser.id');
export var getBiToken = getKey('biToken');
export var getLocale = getKey('locale');
export var getMetaSiteId = getKey('metaSiteId');
export var getInstanceId = getKey('instanceId');
/* protocol + domain + path + query: https://testsite.com/basepath?query=true */
export var getFullSiteUrl = getKey('fullSiteUrl');
/* protocol + domain + path: https://testsite.com/basepath */
export var getSiteUrl = getKey('siteUrl');
export var getCompId = getKey('compId');

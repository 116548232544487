import { __assign, __read } from "tslib";
import _ from 'lodash';
import createAsyncActions from 'shared/redux/helpers/create-async-actions';
import { normalizeArrayOfPaymentInfoVideos, normalizePaymentInfoChannel, } from 'shared/redux/helpers/normalized';
import { getMemberInfo } from 'api/public/channel/channel';
import { getHydratedData } from '../../../client/hydrated-data/hydrated-data';
import { isSiteMode } from 'widget/selectors/view-mode';
import { createAsyncProxy } from 'shared/worker/lib';
var ACTIONS = createAsyncActions('SERVER.CHANNEL.PAYMENT');
export var NAMES = ACTIONS.NAMES;
var START = ACTIONS.START, SUCCESS = ACTIONS.SUCCESS, FAIL = ACTIONS.FAIL;
export var getPaymentInfo = createAsyncProxy('payment.getPaymentInfo', function (id, options) {
    if (options === void 0) { options = {}; }
    return function (dispatch, getState, _a) {
        var withContext = _a.withContext;
        var state = getState();
        var isSite = isSiteMode(state);
        var _b = getHydratedData(state), instance = _b.instance, hydratedMemberInfo = _b.memberInfo, currentSiteUser = _b.currentSiteUser;
        if (!(currentSiteUser && currentSiteUser.id) || !isSite) {
            return Promise.resolve().then(function () { return dispatch(SUCCESS()); });
        }
        var items = state.channelVideos.ids;
        options = __assign(__assign({ instance: instance }, options), { items: items });
        var params = { id: id, options: options };
        dispatch(START(params));
        return withContext(getMemberInfo)(id, instance, {
            hydratedMemberInfo: hydratedMemberInfo,
        }).then(function (response) {
            var items = _.get(response, 'data.items', []);
            if (!items.length) {
                dispatch(SUCCESS(params, response));
                return response;
            }
            var _a = __read(_.partition(items, function (item) { return !item.itemId; }), 2), channelData = _a[0], videoDataList = _a[1];
            var channel = _.get(channelData, '[0]');
            var videos = _.map(videoDataList, function (video) {
                return _.pick(video, 'dgsInfo', 'itemId', 'listId');
            });
            var normalizeChannel = {};
            if (channel) {
                normalizeChannel = __assign({}, normalizePaymentInfoChannel(_.pick(channel, 'dgsInfo', 'listId')));
            }
            /**
             * We are getting channel and video ids from backend in form 75597969-d0e0fda03fb64c7bbed8746046bff110,
             * where 75597969 - publish id, d0e0fda03fb64c7bbed8746046bff110 - item id.
             * This id is stored in itemId property.
             * But when we receive dgsInfo from member info - we have simple id in above mentioned
             * property.
             * So we extracting only dgsInfo, dropping itemId.
             */
            var _b = normalizeArrayOfPaymentInfoVideos(videos), result = _b.result, entities = _b.entities;
            var dgsInfo = _.reduce(entities.videos, function (acc, _a, key) {
                var _b;
                var dgsInfo = _a.dgsInfo;
                return (__assign(__assign({}, acc), (_b = {}, _b[key] = { dgsInfo: dgsInfo }, _b)));
            }, {});
            response = __assign(__assign({}, response), { data: _.merge({}, normalizeChannel, {
                    result: result,
                    entities: __assign(__assign({}, entities), { videos: dgsInfo }),
                }) });
            dispatch(SUCCESS(params, response));
            return response;
        }, function (reason) {
            var meta = {
                analytics: { type: 'error', name: 'shared.channel.payment.get' },
            };
            if (_.isError(reason)) {
                dispatch(FAIL(reason, null, meta));
            }
            else {
                dispatch(FAIL(params, reason, meta));
            }
            return Promise.reject(reason);
        });
    };
});

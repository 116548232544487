import _ from 'lodash';
import { createSelector } from 'reselect';
export function getAllSettings(state) {
    return state.vodSettings;
}
export function getCurrency(state) {
    return _.get(state, 'vodSettings.currency', 'USD');
}
export var getDealInfo = createSelector(getAllSettings, _.property('dealInfo'));
export var getAllowDownload = createSelector(getAllSettings, _.property('allowDownload'));
export var getMemberOnly = createSelector(getAllSettings, _.property('memberOnly'));

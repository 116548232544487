import { __assign, __awaiter, __generator } from "tslib";
import _ from 'lodash';
import createAsyncActions from 'shared/redux/helpers/create-async-actions';
import { getChannelById } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import { isVODChannel } from 'shared/utils/channel-helpers';
import { listChannelVideos } from '@wix/wix-vod-api/dist/src/public/channel-videos/channel-videos';
import { listYoutubeChannelVideos } from '@wix/wix-vod-api/dist/src/public/youtube/videos';
import { normalizeArrayOfVideos } from 'shared/redux/helpers/normalized';
import { getPublic as _getPublicChannel } from 'api/public/channel/channel';
import { getHydratedData } from 'widget/redux/client/hydrated-data/hydrated-data';
var ACTIONS = createAsyncActions('SERVER.CHANNEL.VIDEOS.LIST');
export var NAMES = ACTIONS.NAMES;
var START = ACTIONS.START, SUCCESS = ACTIONS.SUCCESS, FAIL = ACTIONS.FAIL;
export var defaultParams = {
    sort: {
        order: 'custom',
        direction: 'asc',
    },
    paging: {
        size: 1000,
    },
};
export var listPublicChannelVideos = function (channelId, options) {
    if (options === void 0) { options = {}; }
    _.defaultsDeep(options, defaultParams);
    return function (dispatch, getState, _a) {
        var withContext = _a.withContext;
        return __awaiter(void 0, void 0, void 0, function () {
            var getPublicChannel, state, params, _b, hydratedChannel, hydratedChannelVideos, templateMetaSiteId, response, channel, _c, _d, channelResponse, error_1;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        getPublicChannel = withContext(_getPublicChannel);
                        state = getState();
                        params = { id: channelId, options: options };
                        _b = getHydratedData(state), hydratedChannel = _b.channel, hydratedChannelVideos = _b.channelVideos, templateMetaSiteId = _b.templateMetaSiteId;
                        dispatch(START(params));
                        if (!hydratedChannel) return [3 /*break*/, 2];
                        return [4 /*yield*/, getPublicChannel(channelId, { hydratedChannel: hydratedChannel })];
                    case 1:
                        _c = (_e.sent()).data;
                        return [3 /*break*/, 3];
                    case 2:
                        _c = getChannelById(state, channelId);
                        _e.label = 3;
                    case 3:
                        channel = _c;
                        _e.label = 4;
                    case 4:
                        _e.trys.push([4, 14, , 15]);
                        if (!channel) return [3 /*break*/, 9];
                        if (!isVODChannel(channel)) return [3 /*break*/, 6];
                        return [4 /*yield*/, withContext(listChannelVideos)(__assign(__assign({}, options), { channelId: channel.id, hydratedChannelVideos: hydratedChannelVideos,
                                templateMetaSiteId: templateMetaSiteId }))];
                    case 5:
                        _d = _e.sent();
                        return [3 /*break*/, 8];
                    case 6: return [4 /*yield*/, listYoutubeChannelVideos(channel, options, hydratedChannelVideos)];
                    case 7:
                        _d = _e.sent();
                        _e.label = 8;
                    case 8:
                        response = _d;
                        return [3 /*break*/, 13];
                    case 9: return [4 /*yield*/, withContext(listChannelVideos)(__assign(__assign({}, options), { channelId: channelId,
                            templateMetaSiteId: templateMetaSiteId }))];
                    case 10:
                        response = _e.sent();
                        if (!!response.data.length) return [3 /*break*/, 13];
                        return [4 /*yield*/, getPublicChannel(channelId, {
                                hydratedChannel: hydratedChannel,
                            })];
                    case 11:
                        channelResponse = _e.sent();
                        if (!channelResponse.data.externalId) return [3 /*break*/, 13];
                        return [4 /*yield*/, listYoutubeChannelVideos(channelResponse.data, options, hydratedChannelVideos)];
                    case 12:
                        response = _e.sent();
                        _e.label = 13;
                    case 13:
                        response = {
                            paging: response.paging,
                            sort: response.sort,
                            data: normalizeArrayOfVideos(response.data),
                        };
                        dispatch(SUCCESS(params, response));
                        return [2 /*return*/, response];
                    case 14:
                        error_1 = _e.sent();
                        dispatch(FAIL(params, error_1, {
                            analytics: { type: 'error', name: 'shared.channel.videos.list' },
                        }));
                        return [3 /*break*/, 15];
                    case 15: return [2 /*return*/];
                }
            });
        });
    };
};
